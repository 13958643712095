/* latin-ext */
@font-face {
  font-family: "Abril Fatface";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Abril Fatface"), local("AbrilFatface-Regular"), url(./fonts/zOL64pLDlL1D99S8g8PtiKchq-lmjcDidBeT5g.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Abril Fatface";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Abril Fatface"), local("AbrilFatface-Regular"), url(./fonts/zOL64pLDlL1D99S8g8PtiKchq-dmjcDidBc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.serif {
  font-family: "Abril Fatface", Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif",
    Georgia, serif;
}
.sans-serif {
  font-family: system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
}
.mono {
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
    "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
}

::-moz-selection {
  background-color: #000;
  color: #fff;
}
::selection {
  background-color: #000;
  color: #fff;
}
