.coin {
  transition: transform 1s ease-in;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

.side {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.sideA {
  z-index: 100;
}

.sideB {
  transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
}

.coin--heads {
  animation: flipHeads 3s ease-out forwards;
}
.coin--tails {
  animation: flipTails 3s ease-out forwards;
}

@keyframes flipHeads {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(1800deg);
  }
}
@keyframes flipTails {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(1980deg);
  }
}
